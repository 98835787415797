import React from 'react'
import {isMobile, isTablet} from 'react-device-detect'
import { Redirect} from 'react-router-dom'
import { Link} from 'react-router-dom'
import InputMask from "react-input-mask";
import Modals from "./Modals";

export default class Game2 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
        }
    }

    componentDidMount() {
        const iframe = document.getElementById('iframe');

        window.addEventListener("message", this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessage);
    }

    handleMessage = (event) => {
        if (event.data) {
            console.log(event.data);
            let data = JSON.parse(event.data);

            if(data.finished){
                this.modals.gameresShow()
            }
        }
    }

    render(){
        return(
            <>
                <div className="game2__box">
                    <Link to="/" className="modal__close">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="white"/>
                            <rect x="25.25" y="13.0002" width="2.47486" height="17.324" rx="1" transform="rotate(45 25.25 13.0002)" fill="#00BA56"/>
                            <rect x="13" y="14.75" width="2.47486" height="17.324" rx="1" transform="rotate(-45 13 14.75)" fill="#00BA56"/>
                        </svg>
                    </Link>
                    <iframe id="iframe" scrolling="no" frameBorder="0" src="/game2/index.html"/>
                </div>
                <Modals ref={instance => { this.modals = instance; }} page={'home'}/>
            </>
        )
    }
}
