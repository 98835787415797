import React from 'react'
import "../assets/fonts/stylesheet.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/animate.css"
import "slick-carousel/slick/slick.css"
import "../assets/css/style.css"
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Game from './Game'
import Game2 from './Game2'
require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {
    render(){
        return(
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/game' component={Game}/>
                <Route exact path='/game2' component={Game2}/>
            </Switch>
        )
    }
}
